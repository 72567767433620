import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';

import PageLayout from '../layouts/Page';

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <PageLayout>
      <Helmet>
        <title>{frontmatter.title}</title>
        <meta name="description" content={frontmatter.description} />
      </Helmet>
      <div className="blog-post-container">
        <div className="blog-post">
          <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
              <h1 className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {frontmatter.title}
              </h1>
              <p className="mt-2 block text-base text-center tracking-tight text-gray-400">
                {frontmatter.date}
              </p>
              {frontmatter.featuredImage?.childImageSharp?.fluid && (
                <div className="my-4">
                  <Img
                    fluid={frontmatter.featuredImage.childImageSharp.fluid}
                  />
                </div>
              )}
              <div className="mt-6 prose prose-green prose-lg text-gray-500 mx-auto">
                <div
                  className="blog-post-content prose-lg "
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "DD MMMM yyyy", locale: "FR-fr")
        slug
        title
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1232) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
